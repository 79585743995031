<template>
  <div
    class="formatManage"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <span
      class="toLog cs-w"
      @click="handlePage(1)"
    >操作记录</span>
    <div
      class="mainPanel shadowBox"
      v-if="pageIndex===0"
    >
      <div class="formatList fl">
        <div class="buttonPanel">
          <span
            class="buttonCommon buttonEmphasis mr-16"
            @click="handleFormat(0)"
          >添加一级业态</span>
          <span
            class="buttonCommon"
            @click="handleFormat(1)"
          >合并业态</span>
        </div>
        <el-tree
          ref="formatTree"
          v-loading="formatList.length===0"
          class="el-tree-dark"
          show-checkbox
          node-key="id"
          check-strictly
          :expand-on-click-node="false"
          :default-expanded-keys="defaultShowNodes"
          :default-checked-keys="[]"
          :data="formatList"
          :props="defaultProps"
          @node-expand="handleNodeExpand"
          @node-collapse="handleNodeCollapse"
          @node-click="handleNodeClick"
          @node-drag-start="handleDragStart"
          @node-drag-enter="handleDragEnter"
          @node-drag-leave="handleDragLeave"
          @node-drag-over="handleDragOver"
          @node-drag-end="handleDragEnd"
          @node-drop="handleDrop"
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
          <template slot-scope="{ data }">
            <div
              class="el-tree-node__label"
              :style="`background-image:url(${data.img})`"
              :class="{'iconBox':data.img}"
            >
              {{ data.industry }}
            </div>
          </template>
        </el-tree>
      </div>
      <div class="formatDetail fr">
        <div class="header">
          <span>{{formatObj.name ||'--'}}</span>
          <span
            class="fr fs-14"
            @click="handleFormat(3)"
          >
            <i class="icon-edit2"></i>
            编辑
          </span>
          <span
            class="fr fs-14 buttonSpan0"
            @click="handleFormat(2)"
          >
            <i class="icon-add2"></i>
            新增子业态
          </span>
        </div>
        <ul class="formatDetailList">
          <li>上级业态：{{formatObj.father || '--'}}</li>
          <li>
            业态图标：
            <img
              v-if="formatObj.icon"
              width="18"
              height="18"
              :src="formatObj.icon"
              alt=""
            >
            <template v-else>
              无
            </template>
          </li>
          <li>
            业态颜色：
            <span
              v-if="formatObj.color"
              class="colorBox"
              :style="'background-color:'+formatObj.color+''"
            ></span>
            <template v-else>
              无
            </template>
          </li>
          <li>下属品牌：{{formatObj.brands || '--'}}</li>
          <li>创建时间：{{formatObj.date || '--'}}</li>
        </ul>
      </div>
    </div>
    <div
      class="simpleInfoPanel mt-20"
      v-if="pageIndex===1"
    >
      <ul class="filtroPanel">
        <li>
          <span>操作类型：</span>
          <el-select
            v-model="params.type"
            placeholder="请选择"
            popper-class="w-block-select-down"
          >
            <el-option
              v-for="item in tableDataType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li class="w-input-time">
          <span>操作时间：</span>
          <el-date-picker
            popper-class="black-el-date-picker"
            v-model="params.date"
            @change="handleDate"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </li>
        <li class="ml-60">
          <el-button
            class="btnPrimary btnCommon"
            @click="handleFiltro"
          >查询</el-button>
        </li>
      </ul>
    </div>
    <div
      class="mainPanel shadowBox"
      v-if="pageIndex===1"
    >
      <el-table
        :data="tableData"
        highlight-current-row
      >
        <el-table-column
          prop="description"
          label="操作内容"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="操作类型"
        >
          <template slot-scope="scope">
            {{tableDataType[scope.row.type].label}}
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="操作人"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="操作时间"
        >
        </el-table-column>
      </el-table>
      <div class="w-page-sty">
        <el-pagination
          background
          layout="total, prev, pager, next"
          v-if="params.total>10"
          :total="params.total"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          @current-change="handlePages"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      width="398px"
      class="dialog-footer-center"
      :title="dialogFlagArr[dialogFlag]"
      :visible.sync="dialogVisible"
      :before-close="resetFormatForm"
    >
      <ul class="formPanel">
        <template v-if="dialogItemList.indexOf(2)!==-1">
          <li>
            <span class="noStar">待合并：</span>
            <p v-if="checkedList.length">
              <template
                class="noStar"
                v-for="(item, index) in checkedList"
              >
                {{item.industry}}
                {{index===checkedList.length-1?'':'、'}}
              </template>
            </p>
          </li>
          <li>
            <span>合并为：</span>
            <el-input
              v-model="formatForm.name"
              placeholder="请输入合并名称"
            ></el-input>
          </li>
        </template>
        <li v-if="dialogItemList.indexOf(3)!==-1">
          <span>所属业态：</span>
          <el-cascader
            clearable
            v-model="formatForm.fatherFormat"
            :options="formatOptions"
            :props="Object.assign(defaultProps, {value: 'industry'})"
            @change="handleFatherFormat"
          ></el-cascader>
        </li>
        <li v-if="dialogItemList.indexOf(0)!==-1">
          <span>业态名称：</span>
          <el-input
            v-model="formatForm.name"
            placeholder="请输入业态名（限10字内）"
          ></el-input>
        </li>
        <template v-if="dialogItemList.indexOf(1)!==-1">
          <li>
            <span>业态颜色：</span>
            <!-- 京华城topuid 6467 -->
            <!-- <template v-if='user.topuid === 6467'> -->
            <el-color-picker
              v-model="formatForm.color"
              show-alpha
            ></el-color-picker>
            <!-- </template> -->
            <!-- <template v-else>
              <div class="colors-div">
                <el-select
                  :style="{backgroundColor: formatForm.color, color: '#fff'}"
                  v-model="formatForm.color"
                  placeholder="请选择颜色"
                >
                  <el-option
                    v-for="item in colors"
                    :value="item"
                    :label="''"
                    :key="item"
                  >
                    <div :style="{background: item, width: `100%`, height: `32px`}"></div>
                  </el-option>
                </el-select>
                <span
                  class="active-color"
                  :style="{background: formatForm.color}"
                ></span>
              </div>
            </template> -->
          </li>
          <li>
            <span>业态图标：</span>
            <el-upload
              :on-change="handleChange"
              action="/apiaiweb/brandsIndustryMap/upload"
              accept="image/png"
              :show-file-list="false"
              :limit="1"
              :headers="{'token':token}"
              :file-list="fileList"
              :on-success="uploadIconSuccess"
              :on-exceed="uploadIconExceed"
              :before-upload="beforeIconUpload"
              :on-preview="previewIcon"
            >
              <el-button
                size="small"
                type="primary"
              >上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >图标需为png格式，54*54px</div>
            </el-upload>
            <div
              class="iconPreview"
              v-if="formatForm.iconUrl"
            >
              <i
                class="icon-delete2 delBtn pointer"
                title="删除图标"
                @click="deleteIcon"
              ></i>
              <img
                :src="formatForm.iconUrl"
                class="avatar"
              >
            </div>
          </li>
        </template>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="resetFormatForm">取 消</el-button>
        <el-button
          type="primary"
          @click="handleFormatForm"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  name: 'formatManage',
  data () {
    return {
      loading: false,
      formatList: [],
      pageIndex: 0,
      defaultProps: {
        children: 'industrys',
        label: 'industry'
      },
      dialogVisible: false,
      dialogFlag: 0,
      dialogFlagArr: ['添加一级业态', '合并业态', '增加子业态', '编辑'],
      dialogItemList: [],
      fileList: [],
      formatForm: {
        prevName: '',
        name: '',
        color: '#FFA134',
        iconUrl: '',
        toMergeList: [],
        mergeInto: '',
        fatherFormat: []
      },
      checkedList: [],
      isMergeTobeLv1: false,
      formatObj: {
        level: '',
        name: '',
        father: '',
        icon: '',
        color: '',
        brands: '',
        date: ''
      },
      tableData: [],
      tableDataType: [
        {
          value: '',
          label: '所有业态'
        },
        {
          value: 1,
          label: '新增业态'
        },
        {
          value: 2,
          label: '业态改名'
        },
        {
          value: 3,
          label: '合并业态'
        },
        {
          value: 4,
          label: '移动业态'
        }
      ],
      params: {
        stattTime: '',
        endTime: '',
        date: '',
        pageNum: 1,
        pageSize: 10,
        type: '', // 操作类型：1 新增业态；2 业态改名；3 合并业态；4 移动业态
        pages: 1,
        total: 0
      },
      defaultShowNodes: [],
      doOnce: 0,
      formatOptions: [],
      colors: ['#D577D7',
        '#AF61E8', '#755EED',
        '#5282FF', '#29B6E2',
        '#3BAD92', '#E7A1E9',
        '#C180F0', '#9B8AF5',
        '#8BACFF', '#54CCF1',
        '#53CAAD', '#BE2DC1',
        '#9000F8', '#5135E2',
        '#245FF6', '#059AC9',
        '#039471']
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  mounted () {
    this.getDataFormat()
  },
  methods: {
    handleDate (val) {
      if (val) {
        this.params.stattTime = dayjs(val[0]).format('YYYY-MM-DD')
        this.params.endTime = dayjs(val[1]).format('YYYY-MM-DD')
      } else {
        this.params.stattTime = ''
        this.params.endTime = ''
      }
    },
    handlePage (index) {
      this.pageIndex = index
      if (index) {
        this.getDataFormatRecords()
      }
    },
    handlePages (num) {
      this.params.pageNum = num
      this.getDataFormatRecords()
    },
    handleFiltro () {
      this.params.pageNum = 1
      this.getDataFormatRecords()
    },
    getDataFormatRecords () {
      const params = {
        stattTime: this.params.stattTime,
        endTime: this.params.endTime,
        pageNum: this.params.pageNum,
        pageSize: this.params.pageSize,
        type: this.params.type
      }
      this.axios.post(api.getIndustryLog, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.tableData = resData.dataList
          this.params.total = resData.total
          this.params.pages = resData.pages
          console.log('resData', resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleNodeExpand (data) { // 树节点展开
      // 保存当前展开的节点
      let flag = false
      this.defaultShowNodes.some(item => {
        if (item === data.id) { // 判断当前节点是否存在， 存在不做处理
          flag = true
          return true
        }
      })
      if (!flag) { // 不存在则存到数组里
        this.defaultShowNodes.push(data.id)
      }
    },
    // 树节点关闭
    handleNodeCollapse (data) {
      this.defaultShowNodes.some((item, i) => {
        if (item === data.id) {
          // 删除关闭节点
          this.defaultShowNodes.length = i
        }
      })
    },
    handleDragStart (node, ev) {
      // console.log('drag start', node)
    },
    handleDragEnter (draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.label)
    },
    handleDragLeave (draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.label)
    },
    handleDragOver (draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.label)
    },
    handleDragEnd (draggingNode, dropNode, dropType, ev) {
      // console.log('tree drag end: ', dropNode && dropNode.label, dropType)
    },
    handleDrop (draggingNode, dropNode, dropType, ev) { // 父级，原名，排序
      console.log('')
      console.log('draggingNode被拖拽节点对应的Node', draggingNode)
      console.log('dropNode结束拖拽时最后进入的节点', dropNode)
      console.log('dropType被拖拽节点的位置', dropType)
      console.log('ev', ev)
      console.log('tree drop: ', dropNode.label, dropType)
      this.loading = true
      let msgStr = ''
      const params = {
        pindustry: dropNode.data.pindustry,
        oldIndustry: draggingNode.data.industry,
        sort: ''
      }
      if (dropType === 'before') {
        params.sort = dropNode.data.sort
      } else if (dropType === 'after') {
        params.sort = dropNode.data.sort + 1
      } else if (dropType === 'inner') {
        if (dropNode.data.industrys && dropNode.data.industrys.length > 0) {
          params.sort = dropNode.data.industrys.length + 1
        } else {
          params.sort = 1
        }
      }
      if (draggingNode.data.level === 1) { // 1.移动一级
        msgStr = `是否确认移动“${draggingNode.data.industry}”的顺序？`
      } else if (draggingNode.data.level === 2) { // 2.移动二级
        if (draggingNode.data.pindustry === dropNode.data.pindustry) { // 二级：同父级移动
          msgStr = `是否确认移动“${draggingNode.data.industry}”的顺序？`
        } else {
          if (dropNode.data.level === 2) { // 二级：移动到其他一级下的二级前后
            msgStr = `是否确认将“${draggingNode.data.industry}”移到“${dropNode.data.pindustry}”下？`
          } else {
            if (dropType === 'inner') { // 二级：移动到一级中
              msgStr = `是否确认将“${draggingNode.data.industry}”移到“${dropNode.data.industry}”下？`
              params.pindustry = dropNode.data.industry
            } else { // 一级：移成独立一级
              msgStr = `是否确认将“${draggingNode.data.industry}”移成独立的一级？`
            }
          }
        }
      } else if (draggingNode.data.level === 3) {
        if (dropNode.data.level === 3) { // 移动到三级
          if (draggingNode.data.pindustry === dropNode.data.pindustry) { // 三级：同父级移动
            msgStr = `是否确认移动“${draggingNode.data.industry}”的顺序？`
          } else { // 移动到其他三级的前后
            msgStr = `是否确认将“${draggingNode.data.industry}”移到“${dropNode.data.pindustry}”下？`
          }
        } else if (dropNode.data.level === 2) { // 移动到二级中或前后
          if (dropType === 'inner') { // 三级：移动到二级中
            msgStr = `是否确认将“${draggingNode.data.industry}”移到“${dropNode.data.industry}”下？`
            params.pindustry = dropNode.data.industry
          } else { // 二级：移成独立二级
            msgStr = `是否确认将“${draggingNode.data.industry}”移成独立的二级？`
          }
        } else if (dropNode.data.level === 1) { // 移动到一级中或前后
          if (dropType === 'inner') { // 二级：移动到一级中
            msgStr = `是否确认将“${draggingNode.data.industry}”移到“${dropNode.data.industry}”下？`
            params.pindustry = dropNode.data.industry
          } else { // 一级：移成独立一级
            msgStr = `是否确认将“${draggingNode.data.industry}”移成独立的一级？`
          }
        }
      }
      this.$confirm(msgStr, '提示', {
        center: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post(api.moveFormat, params).then(res => {
          if (res.data.code === 0) {
            this.sentMessage(res.data.msg, 1)
            this.getDataFormat()
            this.loading = false
          }
        }, rej => {
          console.log(rej)
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }).catch(() => {
        this.getDataFormat()
        this.loading = false
      })
    },
    allowDrop (draggingNode, dropNode, type) { // 能否被放置
      const data = draggingNode.data
      const dataInto = dropNode.data
      if (data.level === 1) { // 移动的是一级业态
        if (dataInto.level === 1) { // 一级业态移动到一级业态
          return type !== 'inner'
        } else { // 其他
          return false
        }
      } else if (data.level === 2) {
        if (dataInto.level === 1) {
          return true
        } else if (dataInto.level === 2) {
          return type !== 'inner'
        }
      } else if (data.level === 3) {
        if (dataInto.level === 1 || dataInto.level === 2) {
          return true
        } else if (dataInto.level === 3) {
          return type !== 'inner'
        }
      }
    },
    allowDrag (draggingNode) { // 能否被拖拽
      return true
    },
    sentMessage (msg, index) {
      if (!index) {
        index = 0
      }
      const typeArr = ['info', 'warning', 'error', 'success']
      this.$message({
        type: typeArr[index],
        message: msg
      })
    },
    deleteIcon () {
      this.formatForm.iconUrl = ''
      this.fileList = []
    },
    handleNodeClick (data) {
      this.formatObj.level = data.level
      this.formatObj.name = data.industry
      this.formatObj.father = data.pindustry
      this.formatObj.icon = data.img
      this.formatObj.color = data.colour
      this.formatObj.brands = data.haveBrands ? '有' : '无'
      this.formatObj.date = data.createTime
    },
    handleChange () { },
    handleFatherFormat (item) {
      if (item.indexOf('合为一级业态') !== -1) {
        this.dialogItemList.push(1)
      } else {
        this.dialogItemList = this.dialogItemList.filter(item => {
          return item !== 1
        })
      }
      console.log('dialogItemList', this.dialogItemList)
    },
    resetFormatForm () {
      this.dialogVisible = false
      this.formatForm = {
        prevName: '',
        name: '',
        color: '#FFA134',
        iconUrl: '',
        toMergeList: [],
        mergeInto: '',
        fatherFormat: []
      }
      // this.formatObj = {
      //   level: '',
      //   name: '',
      //   father: '',
      //   icon: '',
      //   color: '',
      //   brands: '',
      //   date: ''
      // }
      this.deleteIcon()
    },
    checkFormatName () {
      if (this.formatForm.name === '') {
        return false
      }
      const params = {
        name: this.formatForm.name
      }
      this.axios.post(api.checkIndustry, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    createFormat (params) {
      this.axios.post(api.createFormat, params).then(res => {
        if (res.data.code === 0) {
          this.sentMessage(res.data.msg, 1)
          this.getDataFormat()
          this.resetFormatForm()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleFormat (flag) {
      this.dialogFlag = flag
      this.dialogVisible = true
      if (flag === 0) {
        this.dialogItemList = [0, 1]
      } else if (flag === 1) {
        this.dialogItemList = [2]
        this.checkedList = this.$refs.formatTree.getCheckedNodes()
        if (this.checkedList.length > 1) {
        } else {
          this.sentMessage('请勾选2个以上要合并的业态', 1)
          this.dialogVisible = false
        }
        const currentLevel = [...this.checkedList].shift().level
        const errorList = this.checkedList.filter((item, index) => {
          return item.level !== currentLevel
        })
        if (errorList.length) {
          this.sentMessage('请勾选同级别业态合并', 1)
          this.dialogVisible = false
        }
        if (currentLevel === 1) {
          this.dialogItemList.push(1)
        } else if (currentLevel === 2) {
          this.dialogItemList.push(3)
          this.formatOptions = this.formatOptions.map(item => {
            delete item.industrys
            return item
          })
          const hasextraItem = this.formatOptions.filter(item => {
            return item.industry === '合为一级业态'
          }).length !== 0
          if (!hasextraItem) {
            this.formatOptions.push({
              industry: '合为一级业态',
              level: 1
            })
          }
        } else if (currentLevel === 3) {
          this.dialogItemList.push(3)
          this.formatOptions = this.formatOptions.map(item => {
            if (item.industrys && item.industrys.length) {
              for (let i = 0; i < item.industrys.length; i++) {
                delete item.industrys[i].industrys
              }
            }
            return item
          })
        }
      } else if (flag === 2) {
        this.dialogItemList = [0]
        if (this.formatObj.name === '') {
          this.sentMessage('请先选择业态', 1)
          this.dialogVisible = false
          return false
        } else if (this.formatObj.level === 3) {
          this.sentMessage('三级业态不能添加子业态', 1)
          this.dialogVisible = false
          return false
        }
        this.formatForm.fatherFormat = this.formatObj.name
      } else if (flag === 3) {
        if (this.formatObj.level === 1) {
          this.dialogItemList = [0, 1]
        } else {
          this.dialogItemList = [0]
        }
        if (this.formatObj.name === '') {
          this.sentMessage('请先选择业态', 1)
          this.dialogVisible = false
          return false
        }
        if (this.formatObj.level === 1) {
        }
        this.formatForm.prevName = this.formatObj.name
        this.formatForm.name = this.formatObj.name
        this.formatForm.color = this.formatObj.color
        this.formatForm.iconUrl = this.formatObj.icon
      }
    },
    handleFormatForm () {
      if (this.dialogFlag === 0) {
        const params = {
          colour: this.formatForm.color,
          img: this.formatForm.iconUrl,
          newIndustry: this.formatForm.name
        }
        if (this.formatForm.name === '' || this.formatForm.name.length > 10) {
          this.sentMessage('请输入业态名（限10字内）', 1)
          return false
        } else if (this.formatForm.color === '') {
          this.sentMessage('请选择业态颜色', 1)
          return false
        } else if (this.formatForm.iconUrl === '') {
          this.sentMessage('请上传业态图标', 1)
          return false
        }
        this.createFormat(params)
      } else if (this.dialogFlag === 1) {
        if (this.formatForm.name === '') {
          this.sentMessage('请输入业态名（限10字内）', 1)
          return false
        } else if (this.formatForm.fatherFormat.length === 0 && this.dialogItemList.indexOf(3) !== -1) {
          this.sentMessage('请选择所属业态' + this.formatForm.fatherFormat, 1)
          return false
        } else if (this.dialogItemList.indexOf(1) !== -1) {
          if (this.formatForm.color === '') {
            this.sentMessage('请选择业态颜色', 1)
            return false
          } else if (this.formatForm.iconUrl === '') {
            this.sentMessage('请上传业态图标', 1)
            return false
          }
        }
        const industrys = this.checkedList.map(item => {
          return item.industry
        })
        const params = {
          industrys: industrys,
          newIndustry: this.formatForm.name
        }
        if (this.dialogItemList.indexOf(3) !== -1 && this.formatForm.fatherFormat.indexOf('合为一级业态') === -1) {
          params.pindustry = [...this.formatForm.fatherFormat].pop()
        } else {
          params.pindustry = ''
        }
        if (this.dialogItemList.indexOf(1) !== -1) {
          Object.assign(params, {
            colour: this.formatForm.color,
            img: this.formatForm.iconUrl
          })
        }
        this.$confirm('合并后不可再拆分为原来的，是否确认此操作？', '提示', {
          center: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios.post(api.mergeFormat, params).then(res => {
            if (res.data.code === 0) {
              this.sentMessage(res.data.msg, 3)
              this.getDataFormat()
              this.resetFormatForm()
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        }).catch(() => {
        })
      } else if (this.dialogFlag === 2) {
        this.createFormat({
          newIndustry: this.formatForm.name,
          pindustry: this.formatForm.fatherFormat
        })
      } else if (this.dialogFlag === 3) {
        if (this.formatObj.level === 1) {
          if (this.formatForm.color === '') {
            this.sentMessage('请选择业态颜色', 1)
            return false
          } else if (this.formatForm.iconUrl === '') {
            this.sentMessage('请上传业态图标', 1)
            return false
          }
        }
        const params = {
          colour: this.formatForm.color,
          img: this.formatForm.iconUrl,
          oldIndustry: this.formatForm.prevName,
          newIndustry: this.formatForm.name
        }
        this.axios.post(api.updateFormat, params).then(res => {
          if (res.data.code === 0) {
            this.sentMessage(res.data.msg, 1)
            this.getDataFormat()
            this.resetFormatForm()
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    uploadIconSuccess (res, file, fileList) {
      this.formatForm.iconUrl = res.data
      if (res.code === 0) {
        this.$message({
          message: '上传成功',
          type: 'warning'
        })
      }
    },
    uploadIconExceed () {
      this.$message({
        type: 'warning',
        message: '只能上传一个文件'
      })
    },
    previewIcon (file) {
      console.log('file', file)
    },
    beforeIconUpload (file) {
      // if (file.size / 1024 / 1024 > 10) {
      //   this.$message.error('上传的图片大小不能超过10m')
      //   return false
      // }
      if (file.type !== 'image/png') {
        this.$message.error('请上传PNG文件')
        return false
      }
      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      const listObj = {}
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function () {
          listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
          if (listObj[fileName].height > 54 || listObj[fileName].width > 54) {
            _self.$message.error('上传的图标尺寸大小需为：54*54px')
            // for (let key in listObj[fileName]) {
            //   delete listObj[fileName][key]
            // }
            reject(new Error(0))
          } else {
            resolve()
          }
        }
      })
    },
    getDataFormat () {
      const params = {
      }
      this.axios.post(api.IndustryTree, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.formatList = resData
          if (this.formatList.length && !this.doOnce) {
            this.handleNodeClick(this.formatList[0])
            this.doOnce++
          }
          this.formatOptions = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.formatManage
  color #fff
  position relative
  .toLog
    position absolute
    font-size 14px
    color #666
    cursor pointer
    right 0
    top -22px
  .mt-20
    margin-top 20px
  .shadowBox
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
  .simpleInfoPanel
    margin-top 30px
  .mainPanel
    width 100%
    margin-top 30px
    overflow hidden
    height auto
    .formatList
      width 50%
      min-height 650px
      max-height 650px
      overflow hidden
      height auto
      border-right 1px solid rgba(255, 255, 255, 0.1)
      padding 32px 0 0 40px
      box-sizing border-box
      .buttonCommon
        display inline-block
        color #fff
        width 120px
        height 40px
        line-height 40px
        text-align center
        cursor pointer
        font-size 14px
        border 1px solid #fff
        border-radius 3px
      .buttonEmphasis
        border-color #FFA134
        color #FFA134
      .mr-16
        margin-right 16px
      .el-tree-dark>>>
        margin-top 40px
        max-height 515px
        padding-bottom 30px
        overflow-y scroll
        .el-tree-node__children
          margin-top 8px
    .formatDetail
      width 50%
      .header
        width 100%
        height 66px
        line-height 66px
        font-size 18px
        padding 0 23px 0 39px
        box-sizing border-box
        background #272930
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 0px 3px 3px 3px
        .fs-14
          margin-left 8px
          cursor pointer
        .buttonSpan0
          margin-right 44px
          &:after
            display inline-block
            content ''
            width 1px
            height 28px
            background rgba(255, 255, 255, 0.1)
            position relative
            top 9px
            left 22px
      .formatDetailList
        margin 32px 0 0 40px
        line-height 38px
.fs-14
  font-size 14px
.el-tree-dark>>>
  background #272930
  color #fff
  .el-tree-node:focus>.el-tree-node__content
    background #626265 !important
    width 250px
    position relative
    &:after
      content '\e758'
      font-family 'iconfont'
      font-size 12px
      font-style normal
      display inline-block
      color rgba(160, 160, 160, 1)
      font-weight 100 !important
      position absolute
      right 24px
  .el-tree-node__children
    .el-tree-node__children
      color #A5A5A7 !important
  &:hover
    background-color transparent !important
  .el-checkbox__inner
    background #272930
.icon-edit2, .icon-add2
  color #fff
  font-size 16px
.formPanel
  li
    line-height 38px
    margin-bottom 30px
    overflow hidden
    &:last-child
      margin-bottom 0
    span
      width 83px
      line-height 38px
      float left
      text-align right
      display inline-block
    span:before
      content '*'
      color #FFA134
    span.noStar:before
      content ''
    .el-input
      width 266px
    .el-upload__tip
      float right
      color #999
      margin-right 60px
.el-button--primary:hover
  color #fff !important
  background #FF8B04 !important
.iconPreview
  margin 10px 0 0 84px
  overflow hidden
  width auto
  height auto
  position relative
.delBtn
  position absolute
  font-size 14px
  top -10px
  left 1px
.delBtn:hover
  font-size 15px
.colorBox
  width 16px
  height 16px
  display inline-block
.el-table>>>
  border 1px solid #606266 !important
  border-bottom none !important
  &:before
    height 0 !important
  td, th
    padding 8px 0
  th
    background-color #35373e !important
    border-bottom 1px solid #606266 !important
  td, .el-table__empty-block
    background-color #272930 !important
    color #fff
    border-bottom 1px solid #606266 !important
.ml-60
  margin-left 60px
.el-table
  margin 20px 22px
  width auto
  &:before
    height 0 !important
.iconBox
  background-size 18px 18px
  background-repeat no-repeat
  background-position center left
  text-indent 27px
.w-page-sty
  margin 0 0 20px 0 !important
.colors-div
  display flex
  .active-color
    display inline-block
    width 40px
    height 40px
    border-radius 4px
    margin-left 10px
    &:before
      content ''
</style>
